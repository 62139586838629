import Vue from 'vue';
import Component from 'vue-class-component';
import { ApiServices } from '@/services/api.services';
import SectionColorComponent from '@/components/section-color/section-color.component';
import SectionPhotoTextComponent from '@/components/section-photo-text/section-photo-text.component';
import SectionPhotoPhotoComponent from '@/components/section-photo-photo/section-photo-photo.component';
import SectionTextTextComponent from '@/components/section-text-text/section-text-text.component';
import SectionTextOnlyComponent from '@/components/section-text-only/section-text-only.component';
import SectionComparativeComponent from '@/components/section-comparative/section-comparative.component';
import SectionDownloadComponent from '@/components/section-download/section-download.component';
import SectionImageTextComponent from '@/components/section-image-text/section-image-text.component';
import SectionRelatedProductsComponent from '@/components/section-related-products/section-related-products.component';
import SectionGaleryComponent from '@/components/section-galery/section-galery.component';
import SectionCollageComponent from '@/components/section-collage/section-collage.component';
import { DUNATI_DOMAIN, DUNATI_ORGANIZATION_SCHEMA, ROBOT_CONTENT } from '@/main';
import { StrapiProduct } from '@/class/strapiProduct';

const components: any = {
  SectionPhotoTextComponent,
  SectionTextOnlyComponent,
  SectionColorComponent,
  SectionComparativeComponent,
  SectionDownloadComponent,
  SectionImageTextComponent,
  SectionRelatedProductsComponent,
  SectionGaleryComponent,
  SectionPhotoPhotoComponent,
  SectionCollageComponent,
  SectionTextTextComponent
};

@Component({
  components,
  props: ['category'],
})
export default class ProductDetailPage extends Vue {
  
  public loading: boolean = true;
  public showRelatedProducts: boolean = false;
  public product!: StrapiProduct;

  public path!: string;
  public category!: string;
  public subCategory!: string;

  public mounted() {
    this.$store.dispatch('changeMenu', 'bg1');
    this.getInitData();
  }

  public destroyed() {
    this.$store.dispatch('changeMenu', 'normal');
  }

  public getInitData(s: boolean = false) {
    return new Promise((resolve, reject) => {
      this.category = this.$props.category;
      const slug = this.$route.params.id;
      this.path = this.$route.path;

      ApiServices.getProductBySlug(slug).then(async (response) => {
        if (response.data) {
          this.product = new StrapiProduct(response.data.data);

          if (this.product.showRelatedProducts) {
            this.subCategory = this.product.subcategory.name;
            this.showRelatedProducts = true;
          }

          this.loading = false;
          this.updateTags();

          if (this.product.relatedSlug) {
            this.product.price =  await this.getProductsByRelated(this.product.relatedSlug);
          }

          this.updateJsonld();
          return resolve(true);
        } else {
          this.$router.push('/404');
        }
      }).catch((error) => {
        if (s) {
          let redirect: any = { redirectTo: this.getCategoryUrl() };
          return reject(redirect);
        } else {
          this.$router.push('/404');
        }
      });
    });
  }

  public serverPrefetch() {
    return this.getInitData(true);
  }

  public updateTags() {
    this.$options.dunatiHeadTags = {
      title: `${this.product.name} - Muebles de oficina | Dunati`,
      metaTags: [
        // Primaty Tags /
        {
          name: 'description',
          content: this.product.metaDescription,
        },
        {
          name: 'robot',
          content: ROBOT_CONTENT,
        },
        // Open Graph / Facebook /
        {
          property: 'og:type',
          content: 'product',
        },
        {
          property: 'og:url',
          content: `${DUNATI_DOMAIN}${this.path}`,
        },
        {
          property: 'og:title',
          content: `${this.product.name} - Muebles de oficina | Dunati`,
        },
        {
          property: 'og:description',
          content: this.product.metaDescription,
        },
        {
          property: 'og:image',
          content: `${this.product.img.url}`,
        },
        // Twitter /
        {
          property: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          property: 'twitter:url',
          content: `${DUNATI_DOMAIN}${this.path}`,
        },
        // {
        //   property: 'og:price:amount',
        //   content: this.product.unitPrice,
        // },
        {
          property: 'og:price:currency',
          content: 'CLP',
        },
      ],
    };
    // @ts-ignore
    this.renderTags();
  }

  private updateJsonld() {
    this.$options.dunatiJsonld = [
      DUNATI_ORGANIZATION_SCHEMA,
      {
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [
           {
              "@type": "ListItem",
              "position": 1,
              "item": {
                 "@id": DUNATI_DOMAIN,
                 "name": "Inicio"
              }
           },
           {
              "@type": "ListItem",
              "position": 2,
              "item": {
                 "@id": `${DUNATI_DOMAIN}/productos`,
                 "name": "Productos"
              }
           },
           {
            "@type": "ListItem",
            "position": 3,
            "item": {
               "@id": `${DUNATI_DOMAIN}${this.getCategoryUrl()}`,
               "name": this.getCategoryName().toUpperCase()
            }
          },
           {
              "@type": "ListItem",
              "position": 4,
              "item": {
                 "@id": `${DUNATI_DOMAIN}${this.path}`,
                 "name": this.product.name.toUpperCase(),
              }
           }
        ]
     },
     {
       "@context": "http://schema.org/",
       "@type": "Product",
       "@id": this.product.slug,
       "url": `${DUNATI_DOMAIN}${this.path}`,
       "name": this.product.name,
       "brand": "Dunati",
       "description": this.product.metaDescription,
       "category": `Inicio > PRODUCTOS > ${this.getCategoryName().toUpperCase()} > ${this.product.name.toUpperCase()}`,
       "image": [
          this.product.img.url
       ],
       "offers": {
         "priceCurrency": "CLP",
         "@type": "AggregateOffer",
         "lowPrice": this.product.price || 0,
         "highPrice": this.product.price || 0,
         "offerCount": this.product.colors
       }
     }
    ];

    // @ts-ignore
    this.renderJsonld();
  }

  getCategoryName(): string {
    if (this.category === 'sillas') {
      return 'Sillas de oficina';
    } else if (this.category === 'mesas') {
      return 'Mesas';
    } else if (this.category === 'almacenamiento') {
      return 'Almacenamiento';
    } else if (this.category === 'sofas y sillas multiuso') {
      return 'Sofás y sillas multiuso';
    }

    return '';
  }

  getCategoryUrl(): string {
    if (this.category === 'sillas') {
      return '/sillas-de-oficina';
    } else if (this.category === 'mesas') {
      return '/mesas';
    } else if (this.category === 'almacenamiento') {
      return '/almacenamiento';
    } else if (this.category === 'sofas y sillas multiuso') {
      return '/sofas-y-sillas-multiuso';
    }

    return '/productos';
  }

  private getProductsByRelated(slugs: string): Promise<number> {
    return new Promise(async (resolve, reject) => {
      ApiServices.getProducts(`where[slugs][in]=${[slugs]}`).then((pResponse) => {
        let dataResponse = pResponse.data.data.data || null;
  
        const element = dataResponse.find((x: any) => x.slug === this.product.relatedSlug);
        const price = element? (this.$options as any).filters.formatMoney(+element.unitPrice) : 0;
        resolve(price)
      }, () => {
        resolve(0);
      })
    })
  }
}


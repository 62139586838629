import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { StrapiProduct } from '@/class/strapiProduct';
import { ApiServices } from '@/services/api.services';
import CardProductSComponent from '@/components/card-product-s/card-product-s.component';

@Component({
  components: {
    CardProductSComponent
  },
})
export default class SectionRelatedProductsComponent extends Vue {
  @Prop()
  // @ts-ignore
  public productId: string;
  
  @Prop()
  // @ts-ignore
  public category: string;

  @Prop()
  // @ts-ignore
  public subCategory: string;

  public listRelatedProducts: StrapiProduct[] = [];
  public listPlaceholders = Array(4).fill(1);

  public mounted(): void {
    if (this.productId) {
      this.getRelatedProducts();
    }
  }

  public getRelatedProducts() {
    ApiServices.getStrapiProducts(`where[category]=${this.category}&where[id][ne]=${this.productId}&limit=4`).then((pResponse) => {
      const products: StrapiProduct[] = pResponse.data.data || [];
      let relatedSlugs: string[] = []

      products.forEach((element) => {
        let aux = new StrapiProduct(element);
        this.listRelatedProducts.push(aux);

        if (aux.relatedSlug) {
          relatedSlugs.push(aux.relatedSlug);
        }
      });

      //this.getProductsByRelated(relatedSlugs);
      const len = this.listPlaceholders.length;
      for (let i = 0; i < len; i++) {
        this.listPlaceholders.pop();
      }
    });
  }

  private getProductsByRelated(slugs: string[]): void {
    ApiServices.getProducts(`where[slugs][in]=${slugs}`).then((pResponse) => {
      let dataResponse = pResponse.data.data.data || [];

      dataResponse.forEach((element: any) => {
        this.listRelatedProducts.forEach((product: any) => {
          if (product.relatedSlug === element.slug) {
            this.$nextTick(() => {
              product.price = +element.unitPrice;
            })
          }
        })
      });
    })
  }
}


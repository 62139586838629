import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { SectionDownload } from '@/class/sectionDownload';
import FileModalComponent from '@/components/file-modal/file-modal.component';

const auxComponent: any = {};

if (typeof window !== 'undefined') {
  auxComponent.FileModalComponent = FileModalComponent;
}

@Component({
  components: auxComponent,
})
export default class SectionDownloadComponent extends Vue {
  @Prop({ default: null })
  // @ts-ignore
  public section: SectionDownload;

  @Prop({ default: '' })
  // @ts-ignore
  public productName: string;

  public mounted(): void {}

  public setText(option: string, file: any): void {
    console.log("this.$refs: ", this.$refs);

    // @ts-ignore
    this.$refs['file-modal'].showModal(option, this.productName, false, file);
  }
}


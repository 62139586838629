import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { SectionColor } from '@/class/sectionColor';
import { StrapiFile } from '@/class/strapiFile';

export interface IColor {
  file: StrapiFile | null,
  id: string | null,
  name: string
}


@Component({
  components: {},
})
export default class SectionColorComponent extends Vue {
  @Prop({ default: null })
  // @ts-ignore
  public section: SectionColor;

  // list of colors
  public listColorFiles: IColor[] = [];

  // list of dots
  public listDotFiles: IColor[] = [];

  // current color (from list of colors)
  public currentColor: IColor = {
    file: null,
    id: null,
    name: ''
  };

  // force re-render
  public currentIndex: number = 0;

  public mounted(): void {
    this.listColorFiles = this.section.colors.map(x => {
      return { id: x.id, file: x.file, name: x.name }
    });

    this.listDotFiles = this.section.colors.map(x => {
      return { id: x.id, file: x.colorFile, name: x.name  }
    });


    if (this.listDotFiles.length) {
      this.currentColor = { 
        id: this.listDotFiles[0].id,
        file: this.listDotFiles[0].file,
        name: this.listDotFiles[0].name
      };
    }
  }
  
  setCurrentColor(color: IColor, index: number): void {
    this.currentColor = color;
    this.currentIndex = index;
  }
}

